/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    div: "div",
    a: "a",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Download} = _components;
  if (!Download) _missingMdxReference("Download", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I get a lot of questions about media types (MIME types) when I help customers build integrations. Customers sometimes forget to include a ", React.createElement(_components.code, null, "Content-Type"), " header when making requests to an API, or they send the wrong header and receive an error in response. Let's talk about what kinds of data you can send via an integration and how you declare the type of data you've sent."), "\n", React.createElement(_components.p, null, "Let's say that we've built an API and are sending HTTP messages to it. We send this as part of a message:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\"><span class=\"token punctuation\">{</span>\n  <span class=\"token property\">\"employee\"</span><span class=\"token operator\">:</span> <span class=\"token punctuation\">{</span>\n    <span class=\"token property\">\"eid\"</span><span class=\"token operator\">:</span> <span class=\"token number\">7728</span><span class=\"token punctuation\">,</span>\n    <span class=\"token property\">\"lname\"</span><span class=\"token operator\">:</span> <span class=\"token string\">\"Villarosa\"</span><span class=\"token punctuation\">,</span>\n    <span class=\"token property\">\"accruedhours\"</span><span class=\"token operator\">:</span> <span class=\"token number\">172</span><span class=\"token punctuation\">,</span>\n    <span class=\"token property\">\"fulltime\"</span><span class=\"token operator\">:</span> <span class=\"token boolean\">true</span>\n  <span class=\"token punctuation\">}</span>\n<span class=\"token punctuation\">}</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "It appears that the data is JSON. But if we were to go with this ..."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"xml\"><pre class=\"language-xml\"><code class=\"language-xml\"><span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;</span>employee</span><span class=\"token punctuation\">></span></span>\n  <span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;</span>eid</span><span class=\"token punctuation\">></span></span>7728<span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;/</span>eid</span><span class=\"token punctuation\">></span></span>\n  <span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;</span>lname</span><span class=\"token punctuation\">></span></span>Villarosa<span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;/</span>name</span><span class=\"token punctuation\">></span></span>\n  <span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;</span>accruedhours</span><span class=\"token punctuation\">></span></span>172<span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;/</span>accruedhours</span><span class=\"token punctuation\">></span></span>\n  <span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;</span>fulltime</span><span class=\"token punctuation\">></span></span>true<span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;/</span>fulltime</span><span class=\"token punctuation\">></span></span>\n<span class=\"token tag\"><span class=\"token tag\"><span class=\"token punctuation\">&lt;/</span>employee</span><span class=\"token punctuation\">></span></span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "... it's now obviously XML."), "\n", React.createElement(_components.p, null, "The point is that both are human-readable, so if we don't include descriptive data, we can still understand them. However, there are ", React.createElement(_components.a, {
    href: "https://www.iana.org/assignments/media-types/media-types.xhtml",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "hundreds of types of content"), " that could be used in HTTP messages, and many are binary (so not human-readable except by Johnny Mnemonic)."), "\n", React.createElement(_components.p, null, "To avoid confusing ourselves and any apps we use, we define the message contents as specific media types. This allows the receiving app or system to know the content type without fully parsing the message. Though the current term is media type, the original term was MIME (", React.createElement(_components.strong, null, "m"), "ultipurpose ", React.createElement(_components.strong, null, "i"), "nternet ", React.createElement(_components.strong, null, "m"), "ail ", React.createElement(_components.strong, null, "e"), "xtensions) type. It's also important to remember that we use media types everywhere, not only for HTTP messages."), "\n", React.createElement(_components.p, null, "While many of us are accustomed to thinking of files in terms of their extensions (.docx for MS Word, for example), browsers, websites, and web apps think of files in terms of media types. As a result, a ", React.createElement(_components.code, null, ".docx"), " file is described as ", React.createElement(_components.code, null, "application/vnd.openxmlformats-officedocument.wordprocessingml.document"), ". This construct might seem unwieldy for us, but it is what an app needs to know."), "\n", React.createElement(_components.h2, {
    id: "what-is-a-media-type",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-a-media-type",
    "aria-label": "what is a media type permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), React.createElement(_components.strong, null, "What is a media type?")), "\n", React.createElement(_components.p, null, "A media type is a string that defines the nature and format of a document, file, or other collection of bytes. A media type has two parts: type and subtype."), "\n", React.createElement(_components.p, null, "The type represents the primary category, including things such as application, audio, or text. The subtype further narrows the content. For example, we might have ", React.createElement(_components.code, null, "application/json"), ", ", React.createElement(_components.code, null, "audio/mpeg"), ", and ", React.createElement(_components.code, null, "text/plain"), "."), "\n", React.createElement(_components.p, null, "There is also a unique media type that we should use when working with binary data, and we either don't know the media type or know that there is no corresponding media type. This special media type is ", React.createElement(_components.code, null, "application/octet-stream"), "."), "\n", React.createElement(_components.h2, {
    id: "how-do-we-use-media-types-for-http-messages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-we-use-media-types-for-http-messages",
    "aria-label": "how do we use media types for http messages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do we use media types for HTTP messages?"), "\n", React.createElement(_components.p, null, "We place the media type string in the header of an HTTP request. Here's one for JSON:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\">Content-Type<span class=\"token operator\">:</span> application/json</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "An example HTTP request, with full context, is as follows:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\">POST &lt;https<span class=\"token operator\">:</span><span class=\"token comment\">//api.example.com/customers> HTTP/2.0</span>\nUser-Agent<span class=\"token operator\">:</span> slainte\nHost<span class=\"token operator\">:</span> localhost<span class=\"token operator\">:</span><span class=\"token number\">1234</span>\nContent-Type<span class=\"token operator\">:</span> application/json\n\n<span class=\"token punctuation\">{</span>\n  eid<span class=\"token operator\">:</span><span class=\"token number\">7728</span><span class=\"token punctuation\">,</span>\n  lname<span class=\"token operator\">:</span>'Villarosa'\n<span class=\"token punctuation\">}</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "This example uses POST to submit customer data to the API and notifies the API that we are providing JSON data."), "\n", React.createElement(_components.h2, {
    id: "what-if-we-need-http-responses-in-a-particular-media-type",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-if-we-need-http-responses-in-a-particular-media-type",
    "aria-label": "what if we need http responses in a particular media type permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What if we need HTTP responses in a particular media type?"), "\n", React.createElement(_components.p, null, "While we use Content-Type in an HTTP header to describe the media type in the same message, we can use Accept in a request to an API to tell it that we need to have its response conform to a certain media type. Here's what that might look like:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\">GET &lt;https<span class=\"token operator\">:</span><span class=\"token comment\">//api.example.com/customers> HTTP/2.0</span>\nUser-Agent<span class=\"token operator\">:</span> slainte\nHost<span class=\"token operator\">:</span> localhost<span class=\"token operator\">:</span><span class=\"token number\">1234</span>\nAccept<span class=\"token operator\">:</span> application/json</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "This example uses GET to request a list of customers from the API and notifies it that we expect the data in JSON format."), "\n", React.createElement(_components.p, null, "However, asking for something does not guarantee we'll receive it. Each API has constraints for media type usage. As a result, we should first consult the API documentation to ensure JSON is on the list of supported media types for ", React.createElement(_components.code, null, "api.example.com"), "."), "\n", React.createElement(_components.h2, {
    id: "what-about-multipart-types",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-about-multipart-types",
    "aria-label": "what about multipart types permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What about multipart types?"), "\n", React.createElement(_components.p, null, "Most media types (including all of the above examples) are called discrete media types. That is, they describe sending single data collections or files. However, there is another category of media types called multipart."), "\n", React.createElement(_components.p, null, "This media type is used for HTTP messages which consist of multiple collections or files, each one of which can have a different discrete media type."), "\n", React.createElement(_components.p, null, "Here is an example of a multipart type used to send both JSON data and a PNG file."), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\">POST &lt;https<span class=\"token operator\">:</span><span class=\"token comment\">//api.example.com/customers> HTTP/2.0</span>\nUser-Agent<span class=\"token operator\">:</span> slainte\nHost<span class=\"token operator\">:</span> localhost<span class=\"token operator\">:</span><span class=\"token number\">1234</span>\nContent-Type<span class=\"token operator\">:</span> multipart/form data;boundary=<span class=\"token string\">\"76in9i2w314r\"</span>\n\n--76in9i2w314r\nContent-Disposition<span class=\"token operator\">:</span> form-data; name=<span class=\"token string\">\"customer\"</span>\nContent-Type<span class=\"token operator\">:</span> application/json\n\n<span class=\"token punctuation\">{</span>\n  eid<span class=\"token operator\">:</span><span class=\"token number\">7728</span><span class=\"token punctuation\">,</span>\n  lname<span class=\"token operator\">:</span>'Villarosa'\n<span class=\"token punctuation\">}</span>\n\n--76in9i2w314r\nContent-Disposition<span class=\"token operator\">:</span> form-data; name=<span class=\"token string\">\"customer-photo\"</span>; filename=<span class=\"token string\">\"8ywe683.png\"</span>\nContent-Type<span class=\"token operator\">:</span> image/png\n\n<span class=\"token punctuation\">{</span>file content in binary form<span class=\"token punctuation\">}</span>\n\n--76in9i2w314r--</code></pre></div>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "what-happens-if-we-use-the-wrong-media-type",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-happens-if-we-use-the-wrong-media-type",
    "aria-label": "what happens if we use the wrong media type permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What happens if we use the wrong media type?"), "\n", React.createElement(_components.p, null, "We touched on the fact that each API can support different media types. If API documentation becomes outdated, or we're simply not paying close attention, we might submit an HTTP message which contains a media type that the API doesn't recognize."), "\n", React.createElement(_components.p, null, "Let's say we submit an HTTP message with an ", React.createElement(_components.code, null, "application/octet-stream"), " media type, but the API does not accept it."), "\n", React.createElement(_components.p, null, "We should get back an error response like the following:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"json\"><pre class=\"language-json\"><code class=\"language-json\"><span class=\"token punctuation\">{</span>\n  <span class=\"token property\">\"code\"</span><span class=\"token operator\">:</span> <span class=\"token number\">415</span><span class=\"token punctuation\">,</span>\n  <span class=\"token property\">\"message\"</span><span class=\"token operator\">:</span> <span class=\"token string\">\"HTTP 415 Unsupported Media Type\"</span>\n<span class=\"token punctuation\">}</span></code></pre></div>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "do-we-have-to-use-media-types",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#do-we-have-to-use-media-types",
    "aria-label": "do we have to use media types permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Do we have to use media types?"), "\n", React.createElement(_components.p, null, "Writing the code for a system so it can automatically parse data collections submitted to it is extra work and increases the processing time for involved systems. Using media types does away with that additional effort and ensures consistency in working with different APIs."), "\n", React.createElement(_components.p, null, "For more content on media types and other integration topics, check out our ", React.createElement(_components.a, {
    href: "/resources/api-integrations-a-beginners-guide/"
  }, "guide to API integrations"), "."), "\n", React.createElement(Download, {
    title: "Want to learn more about API integrations?",
    copy: "Download our API Integrations Guide to see what an API integration is and learn how it works.",
    label: "Get my Copy",
    to: "/api-integrations-guide/",
    image: "/images/cta-thumbnails/api-integrations-guide-thumbnail.png"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
